import React from "react";
import styled from "styled-components";
// Components
import TestimonialSlider from "../Elements/TestimonialSlider";
import TeamMemberBox from "../Elements/TeamMemberBox";

// Import images
import amineImage from "../../assets/img/team/amine.jpg";
import ahmedImage from "../../assets/img/team/ahmed.jpg";

export default function Blog() {
  return (
    <Wrapper id="team">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Meet Our Team</h1>
            <p className="font13">
              Get to know the talented individuals behind our projects. Below are some of our key team members.
            </p>
          </HeaderInfo>
          <TeamRow className="row textCenter">
          <TeamMemberWrapper className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <TeamMemberBox
                name="Mohamed Amine Benkhoud"
                position="Project Manager"
                description="Amine has extensive experience in managing complex projects and ensuring successful delivery."
                imgSrc={amineImage}
              />
            </TeamMemberWrapper>
            <TeamMemberWrapper className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <TeamMemberBox
                name="Ahmed Bensalem"
                position="Lead Developer"
                description="Ahmed is an expert in full-stack development with a passion for creating innovative solutions."
                imgSrc={ahmedImage}
              />
            </TeamMemberWrapper>
            
          </TeamRow>
          <div className="row flexCenter">
            <StyledButtonWrapper>
              <StyledButton
                onClick={() => window.open("https://www.facebook.com/profile.php?id=61558055255867", "_blank")}
              >
                Learn More About Us
              </StyledButton>
            </StyledButtonWrapper>
          </div>
        </div>
      </div>
      <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Inspirational Quotes from IT & Development Leaders</h1>
            <p className="font13">
              Discover powerful and motivational quotes from some of the most influential figures in IT and development.
            </p>
          </HeaderInfo>
          <TestimonialSlider />
        </div>
      </div>
    </Wrapper>
  );
}

// Styled components
const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;

const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const TeamRow = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const TeamMemberWrapper = styled.div`
  margin-bottom: 30px;
  flex: 1 1 50%;
  max-width: 50%;
  padding: 0 15px;

  @media (max-width: 768px) {
    max-width: 100%;
    flex: 1 1 100%;
  }
`;

const StyledButtonWrapper = styled.div`
  margin: 50px 0;
  width: 200px;
`;

const StyledButton = styled.button`
  border: 1px solid #1f1b59;
  background-color: #1f1b59;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  cursor: pointer;
  border-radius: 15px;
  font-size: 16px;

  &:hover {
    background-color: #27254b;
    border: 1px solid #27254b;
  }
`;
